import React, { useState, useContext } from "react";
import { IconButton, Stack, Box, Tooltip } from "@mui/material";
//Icons
import { MdDelete, MdModeEdit } from "react-icons/md";
//Srcs
import "../../../Style/actionstyle.scss";
import DeleteProvince from "./DeleteProvince";
import UpdateProvince from "./UpdateProvince";
import { AuthContext } from "../../../Context/AuthContext";
import { translateLauguage } from "../../../Function/Translate";

export default function ProvinceAction({ editData, setRefetch }) {
  // Change Language
  const { language } = useContext(AuthContext);
  const { t } = translateLauguage(language);
  //Modal update
  const [openEdit, setOpenEdit] = useState(false);
  const handleOpenEdit = () => setOpenEdit(true);
  const handleCloseEdit = () => setOpenEdit(false);

  //Modal delete
  const [openDel, setOpenDel] = useState(false);
  const handleOpenDel = () => setOpenDel(true);
  const handleCloseDel = () => setOpenDel(false);

  return (
    <div>
      <Stack direction="row" justifyContent="right">
        <Tooltip title="កែប្រែ" placement="top">
          <IconButton onClick={handleOpenEdit}>
            <Box className="update-container">
              <MdModeEdit className="update-icon" />
            </Box>
          </IconButton>
        </Tooltip>
        <Tooltip title="លុប" placement="top">
          <IconButton onClick={handleOpenDel}>
            <Box className="delete-container">
              <MdDelete className="delete-icon" />
            </Box>
          </IconButton>
        </Tooltip>
      </Stack>

      <UpdateProvince
        open={openEdit}
        handleClose={handleCloseEdit}
        editData={editData}
        setRefetch={setRefetch}
      />

      <DeleteProvince
        open={openDel}
        handleClose={handleCloseDel}
        editData={editData}
        setRefetch={setRefetch}
      />
    </div>
  );
}
