import React, { useContext, useState, useEffect } from "react";
import { useFormik, Form, FormikProvider } from "formik";
import * as Yup from "yup";
//Dialog
import Dialog from "@mui/material/Dialog";
import DialogTitle from "@mui/material/DialogTitle";
import DialogContent from "@mui/material/DialogContent";
import {
  Grid,
  Stack,
  Box,
  TextField,
  Typography,
  Button,
  FormHelperText,
  IconButton,
} from "@mui/material";
import { useMutation, useQuery } from "@apollo/client";
//Icons
import { BiMinusCircle } from "react-icons/bi";
//Src
import "../../../Style/dialogstyle.scss";
import { AuthContext } from "../../../Context/AuthContext";
import { CREATE_COMMUNE } from "../../../Schema/Commune";
import { translateLauguage } from "../../../Function/Translate";
import { SelectDistrict } from "../../Include/DynamicSelect";
export default function CreateCommune({ open, handleClose }) {
  // Change Language
  const { language } = useContext(AuthContext);
  const { t } = translateLauguage(language);
  const { setAlert } = useContext(AuthContext);
  const [loading, setLoading] = useState(false);
  const [districtValue, setDistrictValue] = useState({ id: "", title: "" });

  const [createCommune] = useMutation(CREATE_COMMUNE, {
    onCompleted: ({ createCommune }) => {
      setLoading(false);
      if (createCommune?.status === true) {
        setAlert(true, "success", createCommune?.message);
        handleClose();
        resetForm();
        setDistrictValue({ id: "", title: "" });
      } else {
        setAlert(true, "error", createCommune?.message);
      }
    },
    onError: (error) => {
      setLoading(false);
      setAlert(true, "error", error?.message);
    },
  });

  //fromik
  const ValidatSchema = Yup.object().shape({
    communeName: Yup.string().required("ទាមទារ!"),
    district: Yup.string().required("ទាមទារ!"),
  });

  const formik = useFormik({
    initialValues: {
      communeName: "",
      district: "",
    },
    validationSchema: ValidatSchema,
    onSubmit: async (values) => {
      setLoading(true);
      createCommune({
        variables: {
          ...values,
        },
      });
    },
  });

  const {
    errors,
    touched,
    handleSubmit,
    getFieldProps,
    setFieldValue,
    values,
    resetForm,
  } = formik;

  useEffect(() => {
    setFieldValue("district", districtValue?.id ?? "");
  }, [districtValue?.id]);
  useEffect(() => {
    setDistrictValue({ id: "", title: "" });
    resetForm()
  }, [open]);
  return (
    <Dialog open={open} className="dialog-container" maxWidth="xs" fullWidth>
      <DialogTitle sx={{ padding: "10px 20px" }}>
        <Stack direction="row" spacing={2}>
          <Stack direction="column" justifyContent="center">
            <Typography className="dialog-title">បន្ថែមឃុំ</Typography>
          </Stack>

          <Box sx={{ flexGrow: 1 }} />
          <IconButton onClick={handleClose}>
            <BiMinusCircle className="close-icon" />
          </IconButton>
        </Stack>
      </DialogTitle>

      <DialogContent dividers>
        <FormikProvider value={formik}>
          <Form noValidate autoComplete="off" onSubmit={handleSubmit}>
            <Grid container rowSpacing={2} columnSpacing={3}>
              <Grid item xs={12} md={12}>
                <Typography className="field-title">ជ្រើសរើសស្រុក</Typography>
                <SelectDistrict
                  provinceId={"All"}
                  selectedVal={districtValue}
                  setSelectedVal={setDistrictValue}
                  className={"text-field"}
                />
                {!!errors.district && touched.district && (
                  <FormHelperText error id="outlined-adornment-password">
                    {errors.district}
                  </FormHelperText>
                )}
              </Grid>
              <Grid item xs={12} md={12}>
                <Typography className="field-title">ឈ្មោះឃុំ</Typography>
                <TextField
                  fullWidth
                  size="small"
                  className="text-field"
                  placeholder="ឈ្មោះឃុំ"
                  {...getFieldProps("communeName")}
                  error={Boolean(touched.communeName && errors.communeName)}
                  helperText={touched.communeName && errors.communeName}
                />
              </Grid>
              <Grid item xs={12}>
                {loading ? (
                  <Button className="btn-create" fullWidth>
                    កំពុងដំណើរការ...
                  </Button>
                ) : (
                  <Button
                    className="btn-create"
                    fullWidth
                    onClick={handleSubmit}
                  >
                    បង្កើត
                  </Button>
                )}
              </Grid>
            </Grid>
          </Form>
        </FormikProvider>
      </DialogContent>
    </Dialog>
  );
}
