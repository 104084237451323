import React, { useContext, useEffect, useState } from "react";
import { useFormik, Form, FormikProvider } from "formik";
import * as Yup from "yup";
//Dialog
import Dialog from "@mui/material/Dialog";
import DialogTitle from "@mui/material/DialogTitle";
import DialogContent from "@mui/material/DialogContent";
import {
  Typography,
  IconButton,
  TextField,
  Checkbox,
  Tooltip,
  Button,
  Stack,
  Grid,
  Box,
  FormHelperText,
} from "@mui/material";
import { useMutation } from "@apollo/client";
//Icons
import { BiMinusCircle } from "react-icons/bi";
//date picker
import { DatePicker, LocalizationProvider } from "@mui/x-date-pickers";
import { AdapterDateFns } from "@mui/x-date-pickers/AdapterDateFns";
import moment from "moment";
//Src
import "../../Style/dialogstyle.scss";
import CropImageFile from "../CropImage/CropImageFile";
import { AuthContext } from "../../Context/AuthContext";
import { translateLauguage } from "../../Function/Translate";
import EmptyImage from "../../Assets/empty-image.png";
import { UPDATE_PARTY_MEMBERS } from "../../Schema/PartyMembers";
import { SelectCommune, SelectVillage, SelectPositions } from "../Include/DynamicSelect";

export default function UpdatePartyMembers({ open, handleClose, editData, setRefetch }) {
  // Change Language
  const { language } = useContext(AuthContext);
  const { t } = translateLauguage(language);
  const { setAlert } = useContext(AuthContext);
  const [loading, setLoading] = useState(false);

  const [communeValue, setCommuneValue] = useState({ id: "", title: "" });
  const [villageValue, setVillageValue] = useState({ id: "", title: "" });
  const [positionsVal, setPositionsVal] = useState({ id: "", title: "" });
  const [partyPositionsVal, setPartyPositionsVal] = useState({ id: "", title: "" });
  const [gender, setGender] = useState("ប្រុស");
  // upload Image
  const [profileHook, setProfileHook] = useState("");
  const [imageFile, setImageFile] = useState(null);
  const [openCrop, setOpenCrop] = useState(false);
  const [photoURL, setPhotoURL] = useState(null);

  const handleUploadImage = (e) => {
    const imageFile = e.target.files[0];
    if (imageFile) {
      setImageFile(imageFile);
      setPhotoURL(URL.createObjectURL(imageFile));
      setOpenCrop(true);
    }
  };

  const [updateMember] = useMutation(UPDATE_PARTY_MEMBERS, {
    onCompleted: ({ updateMember }) => {
      setLoading(false);
      if (updateMember?.status === true) {
        setAlert(true, "success", updateMember?.message);
        handleClose();
        setRefetch();
      } else {
        setAlert(true, "error", updateMember?.message);
      }
    },
    onError: (error) => {
      setLoading(false);
      setAlert(true, "error", error?.message);
    },
  });

  //fromik
  const CheckValidation = Yup.object().shape({
    dateOfBirth: Yup.string().required("ទាមទារ!"),
    firstName: Yup.string().required("ទាមទារ!"),
    lastName: Yup.string().required("ទាមទារ!"),
    position: Yup.string().required("ទាមទារ!"),
    commune: Yup.string().required("ទាមទារ!"),
    village: Yup.string().required("ទាមទារ!"),
    gender: Yup.string().required("ទាមទារ!"),
    partyPosition: Yup.string().required("ទាមទារ!"),
    tel: Yup.string(),
    team: Yup.string(),
    profileImage: Yup.string(),
    voteOffice: Yup.string(),
    nationalId: Yup.string(),
    partylId: Yup.string(),
    other: Yup.string(),
  });

  const formik = useFormik({
    initialValues: {
      dateOfBirth: moment([1990]),
      nationalId: "",
      voteOffice: "",
      gender: "ប្រុស",
      partyPosition: "",
      tel: "",
      firstName: "",
      lastName: "",
      position: "",
      partylId: "",
      commune: "",
      village: "",
      other: "",
      team: "",
    },

    validationSchema: CheckValidation,
    onSubmit: async (values) => {
      // console.log("values::", values)
      setLoading(true);
      updateMember({
        variables: {
          id: editData?._id,
          input: {
            ...values,
            profileImage: profileHook,
            team: parseInt(values?.team),
          }
        },
      });
    },
  });

  const {
    errors,
    touched,
    handleSubmit,
    getFieldProps,
    setFieldValue,
    values,
    resetForm,
  } = formik;

  useEffect(() => {
    setFieldValue("gender", gender ?? "");
  }, [gender])

  useEffect(() => {
    setFieldValue("village", villageValue?.id ?? "");
  }, [villageValue]);

  useEffect(() => {
    setFieldValue("commune", communeValue?.id ?? "");
  }, [communeValue]);

  useEffect(() => {
    setFieldValue("position", positionsVal?.id ?? "");
  }, [positionsVal?.id]);

  useEffect(() => {
    setFieldValue("partyPosition", partyPositionsVal?.id ?? "");
  }, [partyPositionsVal?.id]);

  // console.log("editData==>", editData)
  useEffect(() => {
    setGender(editData?.gender);
    setCommuneValue({ id: editData?.communeId, title: editData?.commune });
    setVillageValue({ id: editData?.villageId, title: editData?.village });
    setPositionsVal({ id: editData?.positionId ?? "", title: editData?.position ?? "" })
    setPartyPositionsVal({ id: editData?.partyPositionId ?? "", title: editData?.partyPosition ?? "" })
    setFieldValue("dateOfBirth", editData?.dateOfBirth || "");
    setFieldValue("nationalId", editData?.nationalId || "");
    setFieldValue("voteOffice", editData?.voteOffice || "");
    setFieldValue("firstName", editData?.firstName || "");
    setFieldValue("lastName", editData?.lastName || "");
    setFieldValue("position", editData?.positionId ?? "");
    setFieldValue("partyPosition", editData?.partyPositionId ?? "");
    setFieldValue("partylId", editData?.partylId || "");
    setFieldValue("village", editData?.villageId || "");
    setFieldValue("commune", editData?.commune || "");
    setFieldValue("gender", editData?.gender || "");
    setFieldValue("other", editData?.other || "");
    setFieldValue("team", editData?.team || "");
  }, [editData])

  return (
    <Dialog open={open} className="dialog-container" maxWidth="sm">
      <DialogTitle sx={{ padding: "10px 20px" }}>
        <Stack direction="row" spacing={2}>
          <Stack direction="column" justifyContent="center">
            <Typography className="dialog-title">បង្កើតសមាជិកបក្ស</Typography>
          </Stack>
          <Box sx={{ flexGrow: 1 }} />
          <IconButton onClick={handleClose}>
            <BiMinusCircle className="close-icon" />
          </IconButton>
        </Stack>
      </DialogTitle>

      <DialogContent dividers>
        <FormikProvider value={formik}>
          <Form noValidate autoComplete="off" onSubmit={handleSubmit}>
            <Grid container rowSpacing={1.5} columnSpacing={3}>
              <Grid item xs={12}>
                {!openCrop ? (
                  <Box>
                    <Stack direction="row" justifyContent="center">
                      <Tooltip title="click to upload">
                        <Button component="label">
                          <TextField
                            type="file"
                            id="image"
                            sx={{ display: "none" }}
                            onChange={handleUploadImage}
                          />
                          <img
                            className="avater-image"
                            src={imageFile ? URL.createObjectURL(imageFile) : editData?.profileImage ? editData?.profileImage : EmptyImage}
                          />
                          <input type="file" hidden />
                        </Button>
                      </Tooltip>
                    </Stack>
                    <Typography className="image-title">រូបភាព</Typography>
                  </Box>
                ) : (
                  <CropImageFile
                    openCrop={openCrop}
                    setOpenCrop={setOpenCrop}
                    photoURL={photoURL}
                    setPhotoURL={setPhotoURL}
                    setImageFile={setImageFile}
                    setProfileHook={setProfileHook}
                  />
                )}
                {!!errors.image_src && touched.image_src && (
                  <Stack direction="row" justifyContent="center">
                    <FormHelperText error id="outlined-adornment-password">
                      {errors.image_src}
                    </FormHelperText>
                  </Stack>
                )}
              </Grid>

              <Grid item xs={12} md={6}>
                <Typography className="field-title">
                  នាម<span style={{ color: "red" }}>*</span>
                </Typography>
                <TextField
                  fullWidth
                  size="small"
                  placeholder="នាម"
                  className="text-field"
                  {...getFieldProps("firstName")}
                  error={Boolean(touched.firstName && errors.firstName)}
                  helperText={touched.firstName && errors.firstName}
                />
              </Grid>

              <Grid item xs={12} md={6}>
                <Typography className="field-title">
                  គោត្តនាម<span style={{ color: "red" }}>*</span>
                </Typography>
                <TextField
                  fullWidth
                  size="small"
                  className="text-field"
                  placeholder="គោត្តនាម"
                  {...getFieldProps("lastName")}
                  error={Boolean(touched.lastName && errors.lastName)}
                  helperText={touched.lastName && errors.lastName}
                />
              </Grid>

              <Grid item xs={12} md={6}>
                <Typography className="field-title">
                  ភេទ<span style={{ color: "red" }}>*</span>
                </Typography>
                <Stack direction="row" spacing={2}>
                  <Stack direction="row" spacing={0.5} >
                    <Checkbox
                      sx={{ padding: "2px !important" }}
                      checked={gender === "ប្រុស" ? true : false}
                      onChange={(e) => setGender("ប្រុស")}
                    />
                    <Stack direction="column" justifyContent="center">
                      <Typography className="field-title">
                        ប្រុស
                      </Typography>
                    </Stack>
                  </Stack>
                  <Stack direction="row" spacing={0.5}>
                    <Checkbox
                      sx={{ padding: "2px !important" }}
                      checked={gender === "ស្រី" ? true : false}
                      onChange={(e) => setGender("ស្រី")}
                    />
                    <Stack direction="column" justifyContent="center">
                      <Typography className="field-title">
                        ស្រី
                      </Typography>
                    </Stack>
                  </Stack>
                </Stack>
                {!!errors.gender && touched.gender && (
                  <FormHelperText error id="outlined-adornment-password">
                    {errors.gender}
                  </FormHelperText>
                )}
              </Grid>

              <Grid item xs={12} md={6}>
                <Typography className="field-title" >
                  ថ្ងៃខែឆ្នាំកំណើត<span style={{ color: "red" }}>*</span>
                </Typography>
                <LocalizationProvider
                  className="text-field"
                  dateAdapter={AdapterDateFns}
                >
                  <DatePicker
                    value={values?.dateOfBirth}
                    onChange={(newValue) => { setFieldValue("dateOfBirth", newValue); }}
                    renderInput={(params) => (
                      <TextField
                        className="text-field"
                        size="small"
                        {...params}
                        type="date"
                        fullWidth
                      />
                    )}
                  />
                </LocalizationProvider>
              </Grid>

              <Grid item xs={12} md={6}>
                <Typography className="field-title">
                  លេខទូរស័ព្ទ
                </Typography>
                <TextField
                  className="text-field"
                  fullWidth
                  size="small"
                  placeholder="លេខទូរស័ព្ទ"
                  {...getFieldProps("tel")}
                  error={Boolean(touched.tel && errors.tel)}
                  helperText={touched.tel && errors.tel}
                />
              </Grid>

              <Grid item xs={12} md={6}>
                <Typography className="field-title">
                  តួនាទី
                </Typography>
                <SelectPositions
                  selectedVal={positionsVal}
                  setSelectedVal={setPositionsVal}
                  className={"search-field"}
                />
                {!!errors.position && touched.position && (
                  <FormHelperText error id="outlined-adornment-password">
                    {errors.position}
                  </FormHelperText>
                )}
              </Grid>

              <Grid item xs={12} md={6}>
                <Typography className="field-title">
                  អត្តសញ្ញាណ
                </Typography>
                <TextField
                  className="text-field"
                  fullWidth
                  size="small"
                  placeholder="អត្តសញ្ញាណ"
                  {...getFieldProps("nationalId")}
                  error={Boolean(touched.nationalId && errors.nationalId)}
                  helperText={touched.nationalId && errors.nationalId}
                />
              </Grid>

              <Grid item xs={12} md={6}>
                <Typography className="field-title">
                  ប័ណ្ណសមាជិក
                </Typography>
                <TextField
                  className="text-field"
                  fullWidth
                  size="small"
                  placeholder="ប័ណ្ណសមាជិក"
                  {...getFieldProps("partylId")}
                  error={Boolean(touched.partylId && errors.partylId)}
                  helperText={touched.partylId && errors.partylId}
                />
              </Grid>

              <Grid item xs={12} md={6}>
                <Typography className="field-title">
                  តួនាទីក្នុងបក្ស
                </Typography>
                <SelectPositions
                  selectedVal={partyPositionsVal}
                  setSelectedVal={setPartyPositionsVal}
                  className={"search-field"}
                />
                {!!errors.partyPosition && touched.partyPosition && (
                  <FormHelperText error id="outlined-adornment-password">
                    {errors.partyPosition}
                  </FormHelperText>
                )}
              </Grid>

              <Grid item xs={12} md={6}>
                <Typography className="field-title">
                  ការិ.បោះឆ្នោត
                </Typography>
                <TextField
                  className="text-field"
                  fullWidth
                  size="small"
                  placeholder="ការិ.បោះឆ្នោត"
                  {...getFieldProps("voteOffice")}
                  error={Boolean(touched.voteOffice && errors.voteOffice)}
                  helperText={touched.voteOffice && errors.voteOffice}
                />
              </Grid>

              <Grid item xs={12} md={6}>
                <Typography className="field-title">
                  ឃុំ/សង្កាត់
                </Typography>
                <SelectCommune
                  districtId={"All"}
                  selectedVal={communeValue}
                  setSelectedVal={setCommuneValue}
                  className={"search-field"}
                />
                {!!errors.commune && touched.commune && (
                  <FormHelperText error id="outlined-adornment-password">
                    {errors.commune}
                  </FormHelperText>
                )}
              </Grid>

              <Grid item xs={12} md={6}>
                <Typography className="field-title">
                  ភូមិ
                </Typography>
                <SelectVillage
                  districtId={"All"}
                  communeId={communeValue?.id}
                  selectedVal={villageValue}
                  setSelectedVal={setVillageValue}
                  className={"search-field"}
                />
                {!!errors.village && touched.village && (
                  <FormHelperText error id="outlined-adornment-password">
                    {errors.village}
                  </FormHelperText>
                )}
              </Grid>

              <Grid item xs={12} md={6}>
                <Typography className="field-title">
                  ក្រុមទី
                </Typography>
                <TextField
                  type="number"
                  fullWidth
                  size="small"
                  placeholder="ក្រុមទី"
                  className="text-field"
                  inputProps={{ min: 1 }}
                  {...getFieldProps("team")}
                />
              </Grid>

              <Grid item xs={12} md={6}>
                <Typography className="field-title">
                  ផ្សេងៗ
                </Typography>
                <TextField
                  className="text-field"
                  fullWidth
                  size="small"
                  placeholder="ផ្សេងៗ"
                  {...getFieldProps("other")}
                  error={Boolean(touched.other && errors.other)}
                  helperText={touched.other && errors.other}
                />
              </Grid>

              <Grid item xs={12}>
                {loading ? (
                  <Button className="btn-create" fullWidth>
                    កំពុងដំណើរការ...
                  </Button>
                ) : (
                  <Button
                    className="btn-create"
                    fullWidth
                    onClick={handleSubmit}
                  >
                    កែប្រែ
                  </Button>
                )}
              </Grid>
            </Grid>
          </Form>
        </FormikProvider>
      </DialogContent>
    </Dialog>
  );
}
