import React, { useContext, useState, useEffect } from "react";
import { useFormik, Form, FormikProvider } from "formik";
import * as Yup from "yup";
//Dialog
import Dialog from "@mui/material/Dialog";
import DialogTitle from "@mui/material/DialogTitle";
import DialogContent from "@mui/material/DialogContent";
import {
  Grid,
  Stack,
  Box,
  TextField,
  Typography,
  Button,
  IconButton,
} from "@mui/material";
import { useMutation } from "@apollo/client";
//Icons
import { BiMinusCircle } from "react-icons/bi";
//Src
import "../../../Style/dialogstyle.scss";
import { AuthContext } from "../../../Context/AuthContext";
import { translateLauguage } from "../../../Function/Translate";
import { UPDATE_VILLAGE } from "../../../Schema/Village";
export default function UpdateVillage({
  open,
  handleClose,
  setRefetch,
  editData,
}) {
  // Change Language
  const { language } = useContext(AuthContext);
  const { t } = translateLauguage(language);
  const { setAlert } = useContext(AuthContext);
  const [loading, setLoading] = useState(false);
  const [communeValue, setCommuneValue] = useState({ id: "", title: "" });
  const [updateVillage] = useMutation(UPDATE_VILLAGE, {
    onCompleted: ({ updateVillage }) => {
      setLoading(false);
      if (updateVillage?.status === true) {
        setAlert(true, "success", updateVillage?.message);
        handleClose();
        setRefetch();
        resetForm();
      } else {
        setAlert(true, "error", updateVillage?.message);
      }
    },
    onError: (error) => {
      setLoading(false);
      setAlert(true, "error", error?.message);
    },
  });

  //fromik
  const ValidatSchema = Yup.object().shape({
    villageName: Yup.string().required("ទាមទារ!"),
  });

  const formik = useFormik({
    initialValues: {
      villageName: "",
    },

    validationSchema: ValidatSchema,
    onSubmit: async (values) => {
      setLoading(true);
      updateVillage({
        variables: {
          id: editData?._id,
          ...values,
        },
      });
    },
  });

  const {
    errors,
    touched,
    handleSubmit,
    getFieldProps,
    setFieldValue,
    resetForm,
  } = formik;

  useEffect(() => {
    setFieldValue("villageName", editData?.villageName ?? "");
  }, [editData]);
  return (
    <Dialog open={open} className="dialog-container" maxWidth="xs" fullWidth>
      <DialogTitle sx={{ padding: "10px 20px" }}>
        <Stack direction="row" spacing={2}>
          <Stack direction="column" justifyContent="center">
            <Typography className="dialog-title">កែប្រែឈ្មោះភូមិ</Typography>
          </Stack>

          <Box sx={{ flexGrow: 1 }} />
          <IconButton onClick={handleClose}>
            <BiMinusCircle className="close-icon" />
          </IconButton>
        </Stack>
      </DialogTitle>

      <DialogContent dividers>
        <FormikProvider value={formik}>
          <Form noValidate autoComplete="off" onSubmit={handleSubmit}>
            <Grid container rowSpacing={2} columnSpacing={3}>
              <Grid item xs={12} md={12}>
                <Typography className="field-title">ឈ្មោះភូមិ</Typography>
                <TextField
                  fullWidth
                  size="small"
                  className="text-field"
                  placeholder="ឈ្មោះភូមិ"
                  {...getFieldProps("villageName")}
                  error={Boolean(touched.villageName && errors.villageName)}
                  helperText={touched.villageName && errors.villageName}
                />
              </Grid>
              <Grid item xs={12}>
                {loading ? (
                  <Button className="btn-create" fullWidth>
                    កំពុងដំណើរការ...
                  </Button>
                ) : (
                  <Button
                    className="btn-create"
                    fullWidth
                    onClick={handleSubmit}
                  >
                    កែប្រែ
                  </Button>
                )}
              </Grid>
            </Grid>
          </Form>
        </FormikProvider>
      </DialogContent>
    </Dialog>
  );
}
