import { gql } from "@apollo/client";

export const CREATE_VILLAGE = gql`
  mutation CreateVillage($villageName: String!, $commune: ID!) {
    createVillage(villageName: $villageName, commune: $commune) {
      status
      message
    }
  }
`;

export const UPDATE_VILLAGE = gql`
  mutation UpdateVillage($id: ID!, $villageName: String!) {
    updateVillage(_id: $id, villageName: $villageName) {
      status
      message
    }
  }
`;

export const DELETE_VILLAGE = gql`
  mutation DeleteVillage($id: ID!) {
    deleteVillage(_id: $id) {
      status
      message
    }
  }
`;

export const GET_VILLAGE = gql`
  query GetVillages($communeId: ID!) {
    getVillages(communeId: $communeId) {
      _id
      villageName
      communeName
      communeId
    }
  }
`;
