import { gql } from "@apollo/client"

export const CREATE_POSITIONS = gql`
mutation CreatePosition($title: String) {
    createPosition(title: $title) {
      status
      message
    }
  }
`

export const GET_ALL_POSITIONS = gql`
query GetPositions($keyword: String!) {
    getPositions(keyword: $keyword) {
      _id
      title
    }
  }
`

export const UPDATE_POSITIONS = gql`
mutation UpdatePosition($id: ID!, $title: String) {
  updatePosition(_id: $id, title: $title) {
    status
    message
  }
}
`

export const DELETE_POSITIONS = gql`
mutation DeletePosition($id: ID!) {
  deletePosition(_id: $id) {
    status
    message
  }
}
`