import { Stack, Box, Typography } from "@mui/material";
import CircularProgress from "@mui/material/CircularProgress";
// import LoadingImage from "../../Assets/loading_icon.gif.mp4";

export default function LoadingPage() {
  return (
    <Stack direction="column" justifyContent="center" height="300px" bgcolor="white" width="100%">
      <Stack direction="row" justifyContent="center">
        <CircularProgress color="primary"/> 
      </Stack>
    </Stack>
  );
}
