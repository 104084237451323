import { initializeApp } from "firebase/app";
import { getAuth } from "firebase/auth";
import { getStorage } from "firebase/storage";

// See: https://firebase.google.com/docs/web/learn-more#config-object

// Your web app's Firebase configuration
const firebaseConfig = {
  apiKey: "AIzaSyD8ZgcaJWIalxvM8gffzovYZGWzOE63kD4",
  authDomain: "gocredit-loan-system.firebaseapp.com",
  projectId: "gocredit-loan-system",
  storageBucket: "gocredit-loan-system.appspot.com",
  messagingSenderId: "1056630039204",
  appId: "1:1056630039204:web:799c1abb871252ddfe3c31",
};

// Initialize Firebase
const app = initializeApp(firebaseConfig);

export const auth = getAuth(app);
export const storage = getStorage();
// Initialize Firebase Authentication and get a reference to the service
export default app;
