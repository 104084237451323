import React, { useContext, useState } from "react";
//Dialog
import Dialog from "@mui/material/Dialog";
import DialogTitle from "@mui/material/DialogTitle";
import DialogContent from "@mui/material/DialogContent";
import {
  Grid,
  Stack,
  Box,
  Typography,
  Button,
  IconButton,
} from "@mui/material";
import { useMutation } from "@apollo/client";
//Icons
import { BiMinusCircle } from "react-icons/bi";
//Src
import "../../../Style/dialogstyle.scss";
import { AuthContext } from "../../../Context/AuthContext";
import { DELETE_COMMUNE } from "../../../Schema/Commune";
import { translateLauguage } from "../../../Function/Translate";

export default function DeleteCommune({
  open,
  handleClose,
  editData,
  setRefetch,
}) {
  // Change Language
  const { language } = useContext(AuthContext);
  const { t } = translateLauguage(language);
  const [loading, setLoading] = useState(false);
  const { setAlert } = useContext(AuthContext);

  const [deleteCommune] = useMutation(DELETE_COMMUNE, {
    onCompleted: ({ deleteCommune }) => {
      // console.log("deleteCommune::", deleteCommune);
      setLoading(false);
      if (deleteCommune?.status === true) {
        setAlert(true, "success", deleteCommune?.message);
        handleClose();
        setRefetch();
      } else {
        setAlert(true, "error", deleteCommune?.message);
      }
    },
    onError: (error) => {
      console.log(error);
    },
  });

  const handleDelete = () => {
    setLoading(true);
    deleteCommune({
      variables: {
        id: editData?._id,
      },
    });
  };

  return (
    <Dialog open={open} className="dialog-container" fullWidth maxWidth="xs">
      <DialogTitle sx={{ padding: "10px 20px" }}>
        <Stack direction="row" spacing={2}>
          <Stack direction="column" justifyContent="center">
            <Typography className="dialog-title">លុបឃុំ</Typography>
          </Stack>

          <Box sx={{ flexGrow: 1 }} />
          <Stack direction="column" justifyContent="center">
            <IconButton onClick={handleClose}>
              <BiMinusCircle className="close-icon" />
            </IconButton>
          </Stack>
        </Stack>
      </DialogTitle>

      <DialogContent dividers>
        <Grid container spacing={2}>
          <Grid item xs={12}>
            <Typography>តើអ្នកពិតជាចង់លុបទិន្នន័យនេះមែនទេ?</Typography>
          </Grid>
          <Grid item xs={12}>
            {loading ? (
              <Button className="btn-delete" fullWidth>
                កំពុងដំណើរការ...
              </Button>
            ) : (
              <Button className="btn-delete" fullWidth onClick={handleDelete}>
                លុប
              </Button>
            )}
          </Grid>
        </Grid>
      </DialogContent>
    </Dialog>
  );
}
