import { useState, useEffect } from "react";
import { useQuery } from "@apollo/client";
import { Autocomplete, TextField } from "@mui/material";
import { GET_PROVINCE } from "../../Schema/Province";
import { GET_DISTRICT } from "../../Schema/District";
import { GET_COMMUNE } from "../../Schema/Commune";
import { GET_VILLAGE } from "../../Schema/Village";
import { GET_ALL_POSITIONS } from "../../Schema/Positions";

export function SelectProvince({ selectedVal, setSelectedVal, className }) {
  //Change language
  const [selectOptions, setSelectOptions] = useState([]);
  const [checkRefetch, setCheckRefetch] = useState(false);

  const { refetch } = useQuery(GET_PROVINCE, {
    onCompleted: ({ getProvinces }) => {
      if (getProvinces) {
        const Data = getProvinces?.map((e) => {
          return {
            id: e?._id,
            title: e?.provinceName,
          };
        });
        setSelectOptions(Data);
      }
    },
    onError: (error) => {
      console.log(error?.message);
    },
  });
  const handleSelect = (val) => {
    setSelectedVal(val);
    setCheckRefetch(!checkRefetch);
  };

  useEffect(() => {
    refetch();
  }, [checkRefetch]);

  return (
    <Autocomplete
      disablePortal
      value={selectedVal}
      options={selectOptions}
      getOptionLabel={(option) =>
        option?.title === undefined ? "ជ្រើសរើសខេត្ត" : option?.title
      }
      isOptionEqualToValue={(option, value) => option?.value === value?.value}
      onChange={(event, val) => handleSelect(val)}
      renderInput={(params) => (
        <TextField
          className={className}
          {...params}
          size="small"
          placeholder={"ជ្រើសរើសខេត្ត"}
        />
      )}
    />
  );
}

export function SelectDistrict({
  setSelectedVal,
  selectedVal,
  provinceId,
  className,
  type,
}) {
  //Change language
  const [selectOptions, setSelectOptions] = useState([]);
  const [checkRefetch, setCheckRefetch] = useState(false);

  const { refetch } = useQuery(GET_DISTRICT, {
    variables: {
      provinceId: type === "UPDATE" ? "All" : provinceId ?? "All",
    },
    onCompleted: ({ getDistricts }) => {
      if (getDistricts) {
        const Data = getDistricts?.map((e) => {
          return {
            id: e?._id,
            title: e?.districtName,
          };
        });
        setSelectOptions(Data);
      }
    },
    onError: (error) => {
      console.log(error?.message);
    },
  });
  const handleSelect = (val) => {
    setSelectedVal(val);
    setCheckRefetch(!checkRefetch);
  };

  useEffect(() => {
    refetch();
  }, [checkRefetch]);

  return (
    <Autocomplete
      disablePortal
      disabled={provinceId ? false : true}
      value={selectedVal}
      options={selectOptions}
      getOptionLabel={(option) =>
        option?.title === undefined ? "ជ្រើសរើសស្រុក" : option?.title
      }
      isOptionEqualToValue={(option, value) => option?.value === value?.value}
      onChange={(event, val) => handleSelect(val)}
      renderInput={(params) => (
        <TextField
          className={className}
          {...params}
          size="small"
          placeholder={"ជ្រើសរើសស្រុក"}
        />
      )}
    />
  );
}

export function SelectCommune({
  setSelectedVal,
  selectedVal,
  districtId,
  className,
  type,
}) {
  //Change language
  const [selectOptions, setSelectOptions] = useState([]);
  const [checkRefetch, setCheckRefetch] = useState(false);

  const { refetch } = useQuery(GET_COMMUNE, {
    variables: {
      districtId: type === "UPDATE" ? "All" : districtId ?? "All",
    },
    onCompleted: ({ getCommunes }) => {
      if (getCommunes) {
        const Data = getCommunes?.map((e) => {
          return {
            id: e?._id,
            title: e?.communeName,
          };
        });
        setSelectOptions(Data);
      }
    },
    onError: (error) => {
      console.log(error?.message);
    },
  });
  const handleSelect = (val) => {
    setSelectedVal(val);
    setCheckRefetch(!checkRefetch);
  };

  useEffect(() => {
    refetch();
  }, [checkRefetch]);

  return (
    <Autocomplete
      disablePortal
      value={selectedVal}
      disabled={districtId ? false : true}
      options={selectOptions}
      getOptionLabel={(option) =>
        option?.title === undefined ? "ជ្រើសរើសឃុំ" : option?.title
      }
      isOptionEqualToValue={(option, value) => option?.value === value?.value}
      onChange={(event, val) => handleSelect(val)}
      renderInput={(params) => (
        <TextField
          className={className}
          {...params}
          size="small"
          placeholder={"ជ្រើសរើសឃុំ"}
        />
      )}
    />
  );
}

export function SelectVillage({
  setSelectedVal,
  selectedVal,
  communeId,
  className,
  type,
}) {
  //Change language
  const [selectOptions, setSelectOptions] = useState([]);
  const [checkRefetch, setCheckRefetch] = useState(false);

  const { refetch } = useQuery(GET_VILLAGE, {
    variables: {
      communeId: type === "UPDATE" ? "All" : communeId ?? "All",
    },
    onCompleted: ({ getVillages }) => {
      if (getVillages) {
        const Data = getVillages?.map((e) => {
          return {
            id: e?._id,
            title: e?.villageName,
          };
        });
        setSelectOptions(Data);
      }
    },
    onError: (error) => {
      console.log(error?.message);
    },
  });
  const handleSelect = (val) => {
    setSelectedVal(val);
    setCheckRefetch(!checkRefetch);
  };

  useEffect(() => {
    refetch();
  }, [checkRefetch]);

  return (
    <Autocomplete
      disablePortal
      value={selectedVal}
      disabled={communeId ? false : true}
      options={selectOptions}
      getOptionLabel={(option) =>
        option?.title === undefined ? "ជ្រើសរើសភូមិ" : option?.title
      }
      isOptionEqualToValue={(option, value) => option?.value === value?.value}
      onChange={(event, val) => handleSelect(val)}
      renderInput={(params) => (
        <TextField
          className={className}
          {...params}
          size="small"
          placeholder={"ជ្រើសរើសភូមិ"}
        />
      )}
    />
  );
}

// ================================ Select Positions =======================================
export function SelectPositions({
  setSelectedVal,
  selectedVal,
  className,
}) {
  const [selectOptions, setSelectOptions] = useState([]);
  const [checkRefetch, setCheckRefetch] = useState(false);

  const { refetch } = useQuery(GET_ALL_POSITIONS, {
    variables: {
      keyword: "",
    },
    onCompleted: ({ getPositions }) => {
      // console.log("getPositions::", getPositions)
      if (getPositions?.length !== 0) {
        const Data = getPositions?.map((e) => {
          return {
            id: e?._id,
            title: e?.title,
          };
        });
        setSelectOptions(Data);
      }
    },
    onError: (error) => {
      console.log(error?.message);
    },
  });
  const handleSelect = (val) => {
    setSelectedVal(val);
    setCheckRefetch(!checkRefetch);
  };

  useEffect(() => {
    refetch();
  }, [checkRefetch]);

  return (
    <Autocomplete
      disablePortal
      value={selectedVal}
      options={selectOptions}
      getOptionLabel={(option) =>
        option?.title === undefined ? "ជ្រើសរើសតួនាទី" : option?.title
      }
      isOptionEqualToValue={(option, value) => option?.value === value?.value}
      onChange={(event, val) => handleSelect(val)}
      renderInput={(params) => (
        <TextField
          className={className}
          {...params}
          size="small"
          placeholder={"ជ្រើសរើសតួនាទី"}
        />
      )}
    />
  );
}

