import React, { useContext, useState, useEffect } from "react";
import { useFormik, Form, FormikProvider } from "formik";
import * as Yup from "yup";
//Dialog
import Dialog from "@mui/material/Dialog";
import DialogTitle from "@mui/material/DialogTitle";
import DialogContent from "@mui/material/DialogContent";
import {
  Grid,
  Stack,
  Box,
  TextField,
  FormHelperText,
  Typography,
  Button,
  IconButton,
} from "@mui/material";
import { useMutation, useQuery } from "@apollo/client";
//Icons
import { BiMinusCircle } from "react-icons/bi";
//Src
import "../../../Style/dialogstyle.scss";
import { AuthContext } from "../../../Context/AuthContext";
import { CREATE_DISTRICT } from "../../../Schema/District";
import { translateLauguage } from "../../../Function/Translate";
import { SelectProvince } from "../../Include/DynamicSelect";
export default function CreateDistrict({ open, handleClose }) {
  // Change Language
  const { language } = useContext(AuthContext);
  const { t } = translateLauguage(language);
  const { setAlert } = useContext(AuthContext);
  const [loading, setLoading] = useState(false);
  const [provinceValue, setProvinceValue] = useState({ id: "", title: "" });

  const [createDistrict] = useMutation(CREATE_DISTRICT, {
    onCompleted: ({ createDistrict }) => {
      setLoading(false);
      if (createDistrict?.status === true) {
        setAlert(true, "success", createDistrict?.message);
        handleClose();
        resetForm();
        setProvinceValue({ id: "", title: "" });
      } else {
        setAlert(true, "error", createDistrict?.message);
      }
    },
    onError: (error) => {
      setLoading(false);
      setAlert(true, "error", error?.message);
    },
  });

  //fromik
  const ValidatSchema = Yup.object().shape({
    districtName: Yup.string().required("ទាមទារ!"),
    province: Yup.string().required("ទាមទារ!"),
  });

  const formik = useFormik({
    initialValues: {
      districtName: "",
      province: "",
    },

    validationSchema: ValidatSchema,
    onSubmit: async (values) => {
      setLoading(true);
      createDistrict({
        variables: {
          ...values,
        },
      });
    },
  });

  const {
    errors,
    touched,
    handleSubmit,
    getFieldProps,
    setFieldValue,
    values,
    resetForm,
  } = formik;

  useEffect(() => {
    console.log("provinceValue", provinceValue?.id);
    setFieldValue("province", provinceValue?.id ?? "");
  }, [provinceValue?.id]);

  useEffect(() => {
    setProvinceValue({ id: "", title: "" });
    resetForm()
  }, [open]);
  return (
    <Dialog open={open} className="dialog-container" maxWidth="xs" fullWidth>
      <DialogTitle sx={{ padding: "10px 20px" }}>
        <Stack direction="row" spacing={2}>
          <Stack direction="column" justifyContent="center">
            <Typography className="dialog-title">បន្ថែមស្រុក</Typography>
          </Stack>

          <Box sx={{ flexGrow: 1 }} />
          <IconButton onClick={handleClose}>
            <BiMinusCircle className="close-icon" />
          </IconButton>
        </Stack>
      </DialogTitle>

      <DialogContent dividers>
        <FormikProvider value={formik}>
          <Form noValidate autoComplete="off" onSubmit={handleSubmit}>
            <Grid container rowSpacing={2} columnSpacing={3}>
              <Grid item xs={12} md={12}>
                <Typography className="field-title">ជ្រើសរើសខេត្ត</Typography>
                <SelectProvince
                  selectedVal={provinceValue}
                  setSelectedVal={setProvinceValue}
                  className={"text-field"}
                />
                {!!errors.province && touched.province && (
                  <FormHelperText error id="outlined-adornment-password">
                    {errors.province}
                  </FormHelperText>
                )}
              </Grid>
              <Grid item xs={12} md={12}>
                <Typography className="field-title">ឈ្មោះស្រុក</Typography>
                <TextField
                  fullWidth
                  size="small"
                  className="text-field"
                  placeholder="ឈ្មោះស្រុក"
                  {...getFieldProps("districtName")}
                  error={Boolean(touched.districtName && errors.districtName)}
                  helperText={touched.districtName && errors.districtName}
                />
              </Grid>
              <Grid item xs={12}>
                {loading ? (
                  <Button className="btn-create" fullWidth>
                    កំពុងដំណើរការ...
                  </Button>
                ) : (
                  <Button
                    className="btn-create"
                    fullWidth
                    onClick={handleSubmit}
                  >
                    បង្កើត
                  </Button>
                )}
              </Grid>
            </Grid>
          </Form>
        </FormikProvider>
      </DialogContent>
    </Dialog>
  );
}
