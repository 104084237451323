import { gql } from "@apollo/client";

export const CREATE_PARTY_MEMBERS = gql`
mutation CreateMember($input: AddMemerInput!) {
  createMember(input: $input) {
    status
    message
  }
}
`;

export const DELETE_PARTY_MEMBERS = gql`
mutation DeleteMember($id: ID!) {
  deleteMember(_id: $id) {
    status
    message
  }
} 
`;

export const UPDATE_PARTY_MEMBERS = gql`
mutation UpdateMember($id: ID!, $input: AddMemerInput!) {
  updateMember(_id: $id, input: $input) {
    status
    message
  }
}
`;

export const GET_MEMBERS_PARTY_PAGINATOIN = gql`  
query GetMembersPagination($page: Int!, $limit: Int!, $keyword: String!, $villageId: ID!, $communeId: ID!, $teamNumer: String!, $position: ID!) {
  getMembersPagination(page: $page, limit: $limit, keyword: $keyword, villageId: $villageId, communeId: $communeId, teamNumer: $teamNumer, position: $position) {
    data {
      _id
      firstName
      lastName
      gender
      profileImage
      dateOfBirth
      nationalId
      partylId
      voteOffice
      communeId
      commune
      villageId
      village
      team
      positionId
      position
      tel
      partyPosition
      partyPositionId
      other
    }
    paginator {
      slNo
      prev
      next
      perPage
      totalPosts
      totalPages
      currentPage
      hasPrevPage
      hasNextPage
      totalDocs
    }
  }
}
`;