import React, { useEffect, useState } from "react";
import {
  Typography,
  Stack,
  Box,
  Button,
  TableContainer,
  Table,
  TableRow,
  TableCell,
  TableBody,
  TableHead,
  TextField,
  Grid,
  InputAdornment,
  Avatar,
} from "@mui/material";
import { useQuery } from "@apollo/client";
import moment from "moment";
//Icons
import SearchIcon from "@mui/icons-material/Search";
//Srcs
import "../Style/pagestyle.scss";
import FooterPagination from "../Components/Include/FooterPagination";
import LoadingPage from "../Components/Include/LoadingPage";
import EmptyData from "../Components/Include/EmptyData";
import CreateIcon from "../Assets/create-icon.svg";
import PartyMembersAction from "../Components/PartyMembers/PartyMembersAction";
import CreatePartyMembers from "../Components/PartyMembers/CreatePartyMembers";
import { SelectCommune, SelectVillage, SelectPositions } from "../Components/Include/DynamicSelect";
import { GET_MEMBERS_PARTY_PAGINATOIN } from "../Schema/PartyMembers";

export default function PartyMembers() {
  const [loading, setLoading] = useState(true);

  const [open, setOpen] = useState(false);
  const handleOpen = () => setOpen(true);
  const handleClose = () => setOpen(false);

  const [communeValue, setCommuneValue] = useState({ id: "", title: "" });
  const [villageValue, setVillageValue] = useState({ id: "", title: "" });
  const [positionsVal, setPositionsVal] = useState({ id: "", title: "" });
  const [tableData, setTableData] = useState([]);
  const [keyword, setKeyword] = useState("");
  const [paginationData, setPaginationData] = useState();
  const [page, setPage] = useState(1);
  const [limit, setLimit] = useState(10);
  const [team, setTeam] = useState("");
  // console.log("villageValue===>", villageValue?.id)
  // console.log("communeValue===>", communeValue?.id)

  const { refetch } = useQuery(GET_MEMBERS_PARTY_PAGINATOIN, {
    variables: {
      page: page,
      limit: limit,
      keyword: keyword,
      villageId: villageValue?.id || "ទាំងអស់",
      communeId: communeValue?.id || "ទាំងអស់",
      teamNumer: team,
      position: positionsVal?.id || "ទាំងអស់",
    },
    onCompleted: ({ getMembersPagination }) => {
      // console.log("getMembersPagination", getMembersPagination)
      setTableData(getMembersPagination?.data);
      setPaginationData(getMembersPagination?.paginator);
      setLoading(false);
    },
    onError: (err) => {
      console.log("Error::", err?.message);
    },
  });

  const handleLimit = (e) => {
    setLimit(e.target.value);
    setPage(1);
  };

  // ======================= Resize width Screen ======================
  const [width, setWidth] = useState(window.innerWidth);
  const updateDimensions = () => {
    setWidth(window.innerWidth - 50);
  };
  useEffect(() => {
    window.addEventListener("resize", updateDimensions);
    return () => window.removeEventListener("resize", updateDimensions);
  }, []);

  useEffect(() => {
    setTeam("");
    setVillageValue({ id: "", title: "" });
  }, [communeValue])
  return (
    <div className="page-container">
      <Stack direction="row" spacing={2} className="page-header">
        <Stack direction="column" justifyContent="center">
          <Box className="slash" />
        </Stack>
        <Stack direction="column" justifyContent="center">
          <Typography className="page-title">សមាជិកបក្ស</Typography>
        </Stack>
      </Stack>

      <Box sx={{ marginTop: "30px" }}>
        <Grid container spacing={2}>
          <Grid item xs={6} sm={3} md={2.4} lg={2} xl={1.8}>
            <Typography className="header-text">ស្វែងរកសមាជិក</Typography>
            <TextField
              placeholder="ស្វែងរក"
              size="small"
              className="search-field"
              onChange={(e) => setKeyword(e.target.value)}
              InputProps={{
                startAdornment: (
                  <InputAdornment
                    position="start"
                    sx={{ mr: 1, color: "black" }}
                  >
                    <SearchIcon />
                  </InputAdornment>
                ),
              }}
            />
          </Grid>
          <Grid item xs={6} sm={3} md={2.4} lg={2} xl={1.8}>
            <Typography className="header-text">ជ្រើសរើសតួនាទី</Typography>
            <SelectPositions
              selectedVal={positionsVal}
              setSelectedVal={setPositionsVal}
              className={"search-field"}
            />
          </Grid>
          <Grid item xs={6} sm={3} md={2.4} lg={2} xl={1.8}>
            <Typography className="header-text">ជ្រើសរើសឃុំ</Typography>
            <SelectCommune
              districtId={"All"}
              selectedVal={communeValue}
              setSelectedVal={setCommuneValue}
              className={"search-field"}
            />
          </Grid>
          <Grid item xs={6} sm={3} md={2.4} lg={2} xl={1.8}>
            <Typography className="header-text">ជ្រើសរើសភូមិ</Typography>
            <SelectVillage
              districtId={"All"}
              communeId={communeValue?.id}
              selectedVal={villageValue}
              setSelectedVal={setVillageValue}
              className={"search-field"}
            />
          </Grid>

          <Grid item xs={6} sm={2} md={2.4} lg={1} xl={1}>
            <Typography className="header-text">ក្រុមទី</Typography>
            <TextField
              size="small"
              value={team}
              type="number"
              placeholder="ក្រុមទី"
              inputProps={{ min: 1 }}
              className="search-field"
              disabled={villageValue?.id ? false : true}
              onChange={(e) => setTeam(e?.target?.value)}
            />
          </Grid>
          <Grid item xs={6} sm={10} md={12} lg={3} xl={3.8}>
            <Stack direction="row" justifyContent="right">
              <Button
                onClick={handleOpen}
                className="btn-create"
                startIcon={<img src={CreateIcon} alt="CreateIcon" className="icon-add" />}
              >
                បង្កើត
              </Button>
              <CreatePartyMembers
                open={open}
                handleClose={handleClose}
                setRefetch={refetch}
              />
            </Stack>
          </Grid>
        </Grid>
      </Box>

      <Box className="body-container">
        <TableContainer sx={{ maxWidth: `${width}px` }}>
          <Table className="table" aria-label="simple table">
            <TableHead className="header-row ">
              <TableCell className="header-title-start" width="4%">ល.រ</TableCell>
              <TableCell className="header-title" width="10%">នាម និងគោត្តនាម</TableCell>
              <TableCell className="header-title" width="6%" align="center">ភេទ</TableCell>
              <TableCell className="header-title" width="7%" align="center">ថ្ងៃខែឆ្នាំកំណើត</TableCell>
              <TableCell className="header-title" width="8%" align="center">អត្តសញ្ញាណ</TableCell>
              <TableCell className="header-title" width="10%" align="center">តួនាទី</TableCell>
              <TableCell className="header-title" width="7%" align="center">ប័ណ្ណសមាជិក</TableCell>
              <TableCell className="header-title" width="7%" align="center">ការិ.បោះឆ្នោត</TableCell>
              <TableCell className="header-title" width="5%" align="center">ក្រុម</TableCell>
              <TableCell className="header-title" width="11%" >ឃុំ/សង្កាត់</TableCell>
              <TableCell className="header-title" width="11%">ភូមិ</TableCell>
              <TableCell className="header-title" width="19%">ផ្សេងៗ</TableCell>
              <TableCell className="header-title-end" width="5%" ></TableCell>
            </TableHead>
            {loading ? (
              <TableBody className="body">
                <TableRow className="body-row">
                  <TableCell colSpan={14} className="body-cell">
                    <LoadingPage />
                  </TableCell>
                </TableRow>
              </TableBody>
            ) : (
              <>
                {tableData?.length === 0 ? (
                  <TableBody className="body">
                    <TableRow className="body-row">
                      <TableCell colSpan={14} className="body-cell">
                        <EmptyData />
                      </TableCell>
                    </TableRow>
                  </TableBody>
                ) : (
                  <TableBody className="body">
                    {tableData?.map((row, index) => {
                      return (
                        <TableRow className="body-row" key={index}>
                          <TableCell className="body-cell-start">
                            {(index + paginationData?.slNo) < 10 ? "0" + (index + paginationData?.slNo) : (index + paginationData?.slNo)}
                          </TableCell>
                          <TableCell className="body-cell">
                            <Stack direction="row" spacing={1}>
                              <Avatar
                                sx={{ width: 40, height: 40 }}
                                alt={row?.firstName}
                                src={row?.profileImage}
                              />
                              <Stack direction="column" justifyContent="center">
                                <Typography className="member-name">
                                  {row?.lastName + " " + row?.firstName}
                                </Typography>
                              </Stack>
                            </Stack>
                          </TableCell>
                          <TableCell className="body-cell" align="center"> {row?.gender} </TableCell>
                          <TableCell className="body-cell" align="center"> {moment(row?.dateOfBirth).format("DD-MM-YYYY")} </TableCell>
                          <TableCell className="body-cell" align="center"> {row?.nationalId} </TableCell>
                          <TableCell className="body-cell" align="center"> {row?.position}</TableCell>
                          <TableCell className="body-cell" align="center"> {row?.partylId}</TableCell>
                          <TableCell className="body-cell" align="center"> {row?.voteOffice} </TableCell>
                          <TableCell className="body-cell" align="center"> {row?.team} </TableCell>
                          <TableCell className="body-cell"  > {row?.commune} </TableCell>
                          <TableCell className="body-cell"> {row?.village} </TableCell>
                          <TableCell className="body-cell"> {row?.other ?? "--:--"} </TableCell>
                          <TableCell className="body-cell-end" align="right">
                            <PartyMembersAction editData={row} setRefetch={refetch} />
                          </TableCell>
                        </TableRow>
                      )
                    })}
                  </TableBody>
                )}
              </>
            )}
          </Table>
        </TableContainer>

        {/* ==================== Pagination  ============================= */}
        <FooterPagination
          totalPages={paginationData?.totalPages}
          totalDocs={paginationData?.totalDocs}
          limit={limit}
          page={page}
          setPage={setPage}
          handleLimit={handleLimit}
        />
      </Box>
    </div>
  );
}
