import React, { useContext, useState } from "react";
import {
  Stack,
  IconButton,
  Typography,
  Button,
  Dialog,
  DialogContent,
  DialogTitle,
} from "@mui/material";
import { useNavigate } from "react-router-dom";
import DoDisturbOnOutlinedIcon from "@mui/icons-material/DoDisturbOnOutlined";
import CircularProgress from "@mui/material/CircularProgress";
//Srcs
import { AuthContext } from "../../Context/AuthContext";
import Auth from "../../Auth";

export default function LogoutMenu({ open, handleClose }) {

  const [iconReload, setIconReload] = useState(false);
  const { dispatch, setAlert } = useContext(AuthContext);

  //======================== function logout ===================
  const handleLogout = async () => {

    setIconReload(true);
    const logout = await Auth.logout();

    if (logout?.status) {
      setTimeout(() => {
        handleClose();
        setIconReload(false);
        //================== remove token ======================
        window.localStorage.removeItem("token");
        window.localStorage.removeItem("userLogin");
        window.localStorage.removeItem("userStatus");
        dispatch({
          type: "LOGGED_IN_USER",
          payload: null,
        });
        window.location.reload("/login");
      }, 2300);
    } else {
      setAlert(true, "error", logout?.status?.message);
      setIconReload(false);
    }
  };

  return (
    <Dialog open={open}>
      <DialogTitle>
        <Stack
          direction="row"
          justifyContent="right"
          sx={{ marginTop: "-5px" }}
        >
          <IconButton onClick={handleClose}>
            <DoDisturbOnOutlinedIcon sx={{ color: "red", fontSize: "25px" }} />
          </IconButton>
        </Stack>
      </DialogTitle>
      <DialogContent>
        <Stack direction="column" justifyContent="center" height="150px">
          <Stack
            direction="row"
            justifyContent="center"
            width="400px"
            marginTop="-10px"
          >
            <Typography
              sx={{
                fontWeight: "bold",
                color: "black",
                fontSize: "20px",
              }}
            >
              You are attemping to log out.
            </Typography>
          </Stack>
          <Stack direction="row" justifyContent="center" width="400px">
            <Typography sx={{ color: "black", fontSize: "15px" }}>
              Are you sure?
            </Typography>
          </Stack>

          <Stack
            direction="row"
            justifyContent="center"
            width="400px"
            marginTop="30px"
          >
            <Button
              onClick={handleLogout}
              autoFocus
              fullWidth
              sx={{
                backgroundColor: "#0f81c2",
                color: "white",
                ":hover": {
                  backgroundColor: "#0f81c2",
                },
              }}
              endIcon={
                iconReload ? (
                  <CircularProgress sx={{ color: "#fff" }} size="20px" />
                ) : null
              }
            >
              ចាកចេញ
            </Button>
          </Stack>
        </Stack>
      </DialogContent>
    </Dialog>
  );
}
