import React, { useState } from "react";
import { IconButton, Stack, Box, Tooltip } from "@mui/material";
//Icons
import { MdDelete } from "react-icons/md";
import { IoKeyOutline } from "react-icons/io5";
//Srcs
import "../../Style/actionstyle.scss";
import DeleteUser from "./DeleteUser";
import ResetPassword from "./ResetPassword";

export default function UserAction({ editData, setRefetch }) {
  //Modal update
  const [openEdit, setOpenEdit] = useState(false);
  const handleOpenEdit = () => setOpenEdit(true);
  const handleCloseEdit = () => setOpenEdit(false);

  //Modal delete
  const [openDel, setOpenDel] = useState(false);
  const handleOpenDel = () => setOpenDel(true);
  const handleCloseDel = () => setOpenDel(false);

  return (
    <div>
      <Stack direction="row" justifyContent="right">
        <Tooltip title="កែប្រែពាក្យសម្ងាត់" placement="top">
          <IconButton onClick={handleOpenEdit}>
            <Box className="update-container">
              <IoKeyOutline className="update-icon" />
            </Box>
          </IconButton>
        </Tooltip>
        <Tooltip title="លុប" placement="top">
          <IconButton onClick={handleOpenDel}>
            <Box className="delete-container">
              <MdDelete className="delete-icon" />
            </Box>
          </IconButton>
        </Tooltip>
      </Stack>

      <ResetPassword
        open={openEdit}
        handleClose={handleCloseEdit}
        editData={editData}
        setRefetch={setRefetch}
      />

      <DeleteUser
        open={openDel}
        handleClose={handleCloseDel}
        editData={editData}
        setRefetch={setRefetch}
      />
    </div>
  );
}
