import React, { useState, useContext } from "react";
import {
  IconButton,
  List,
  ListItem,
  ListItemButton,
  ListItemIcon,
  Typography,
  Stack,
  Box,
} from "@mui/material";
import MuiDrawer from "@mui/material/Drawer";
import { styled } from "@mui/material/styles";
import { useLocation, useNavigate } from "react-router-dom";
//Icons
import { AiOutlineSetting } from "react-icons/ai";
import { PiStudentBold } from "react-icons/pi";
import { HiOutlineCake } from "react-icons/hi";
import CampaignOutlinedIcon from "@mui/icons-material/CampaignOutlined";
import GroupsIcon from '@mui/icons-material/Groups';
//Srcs
import CompanyLogo from "../../Assets/logo.svg";
import "./menunavbar.scss";
import { AuthContext } from "../../Context/AuthContext";
import { translateLauguage } from "../../Function/Translate";

const drawerWidth = 270;

const openedMixin = (theme) => ({
  width: drawerWidth,
  transition: theme.transitions.create("width", {
    easing: theme.transitions.easing.sharp,
    duration: theme.transitions.duration.enteringScreen,
  }),
  overflowX: "hidden",
});

const closedMixin = (theme) => ({
  transition: theme.transitions.create("width", {
    easing: theme.transitions.easing.sharp,
    duration: theme.transitions.duration.leavingScreen,
  }),
  overflowX: "hidden",
  width: `calc(${theme.spacing(7)} + 1px)`,
  [theme.breakpoints.up("sm")]: {
    width: `calc(${theme.spacing(8)} + 1px)`,
  },
});

const Drawer = styled(MuiDrawer, {
  shouldForwardProp: (prop) => prop !== "open",
})(({ theme, open }) => ({
  width: drawerWidth,
  height: "100%",
  flexShrink: 0,
  whiteSpace: "nowrap",
  boxSizing: "border-box",
  ...(open && {
    ...openedMixin(theme),
    "& .MuiDrawer-paper": openedMixin(theme),
  }),
  ...(!open && {
    ...closedMixin(theme),
    "& .MuiDrawer-paper": closedMixin(theme),
  }),
}));

const DrawerHeader = styled("div")(({ theme }) => ({
  display: "flex",
  margin: "0px",
  alignItems: "center",
  justifyContent: "center",
  padding: theme.spacing(0, 1),
  // necessary for content to be below app bar
  ...theme.mixins.toolbar,
}));

export default function MenuNavbar({ open, handleDrawerClose }) {
  // Change Language
  const { language } = useContext(AuthContext);
  const { t } = translateLauguage(language);

  let location = useLocation();
  const navigate = useNavigate();

  // console.log("location", location)

  const [openIncome, setOpenIncome] = useState(false);
  const handleOpenIncome = () => {
    setOpenIncome(!openIncome);
    setOpenExpenses(false);
  };

  const [openExpenses, setOpenExpenses] = useState(false);
  const handleOpenExpenses = () => {
    setOpenExpenses(!openExpenses);
    setOpenIncome(false);
  };

  const handleCloseSubMenu = () => {
    setOpenExpenses(false);
    setOpenIncome(false);
  };

  const [openMealService, setOpenMealService] = useState(false);
  const handleOpenMealService = () => {
    setOpenMealService(!openMealService);
  };
  return (
    <Drawer variant="permanent" open={open} className="drawer-menu">
      <DrawerHeader sx={{ mt: 2 }}>
        <Stack direction="row" justifyContent="center" sx={{ width: "100%" }}>
          <IconButton
            onClick={() => navigate("/party-members")}
            className="image-container"
          >
            <img src={CompanyLogo} alt="logo" className="logo" />
          </IconButton>
        </Stack>
      </DrawerHeader>

      <List className="list-menu">

        {/* ============================== Page Students ========================== */}
        <ListItem
          className={
            location.pathname === "/party-members" ? "list-item-active" : "list-item"
          }
          disablePadding
          onClick={() => navigate("/party-members")}
        >
          <ListItemButton className="list-item-button">
            <ListItemIcon className="list-item-icon">
              <GroupsIcon className="icon" />
            </ListItemIcon>
            <Typography className="list-item-text">សមាជិកបក្ស</Typography>
          </ListItemButton>
        </ListItem>
      </List>

      <Box sx={{ flexGrow: 1 }}></Box>
      <List className="list-menu">
        {/* =============================== Page Setting =============================== */}
        <ListItem
          className={
            location.pathname === "/setting" ||
              location.pathname === "/setting/user" ||
              location.pathname === "/setting/address" ||
              location.pathname === "/setting/positions"
              ? "list-item-active"
              : "list-item"
          }
          disablePadding
          onClick={() => navigate("/setting")}
        >
          <ListItemButton className="list-item-button">
            <ListItemIcon className="list-item-icon">
              <AiOutlineSetting className="icon" />
            </ListItemIcon>
            <Typography className="list-item-text">ការកំណត់</Typography>
          </ListItemButton>
        </ListItem>
      </List>
    </Drawer>
  );
}
