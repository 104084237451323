import { gql } from "@apollo/client";

export const CREATE_COMMUNE = gql`
  mutation CreateCommune($communeName: String!, $district: ID) {
    createCommune(communeName: $communeName, district: $district) {
      status
      message
    }
  }
`;

export const GET_COMMUNE = gql`
  query GetCommunes($districtId: ID!) {
    getCommunes(districtId: $districtId) {
      _id
      communeName
      districtName
      districtId
    }
  }
`;

export const UPDATE_COMMUNE = gql`
  mutation UpdateCommune($id: ID!, $communeName: String!) {
    updateCommune(_id: $id, communeName: $communeName) {
      status
      message
    }
  }
`;

export const DELETE_COMMUNE = gql`
  mutation DeleteCommune($id: ID!) {
    deleteCommune(_id: $id) {
      status
      message
    }
  }
`;
