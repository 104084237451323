import React, { useState, useContext, useEffect } from "react";
import {
  Box,
  TableContainer,
  Table,
  TableRow,
  TableCell,
  TableBody,
  TableHead,
} from "@mui/material";
import { useQuery } from "@apollo/client";
//Srcs
import EmptyData from "../../../Components/Include/EmptyData";
import LoadingPage from "../../../Components/Include/LoadingPage";
import { AuthContext } from "../../../Context/AuthContext";
import { translateLauguage } from "../../../Function/Translate";
import { GET_COMMUNE } from "../../../Schema/Commune";
import CommuneAction from "./CommuneAction";
export default function Commune() {
  // Change Language
  const { language } = useContext(AuthContext);
  const { t } = translateLauguage(language);
  const [loading, setLoading] = useState(true);

  const [tableData, setTableData] = useState([]);
  const { refetch } = useQuery(GET_COMMUNE, {
    variables: {
      districtId: "All",
    },
    onCompleted: ({ getCommunes }) => {
      setTableData(getCommunes);
      setLoading(false);
    },
    onError: (err) => {
      console.log("Error::", err?.message);
    },
  });

  // ======================= Resize width Screen ======================
  const [width, setWidth] = useState(window.innerWidth);
  const updateDimensions = () => {
    setWidth(window.innerWidth - 50);
  };
  useEffect(() => {
    window.addEventListener("resize", updateDimensions);
    return () => window.removeEventListener("resize", updateDimensions);
  }, []);

  return (
    <div className="page-container">
      <Box className="body-container">
        <TableContainer sx={{ maxWidth: `${width}px` }}>
          <Table className="table" aria-label="simple table">
            <TableHead className="header-row ">
              <TableCell className="header-title-start" width="15%"> ល.រ  </TableCell>
              <TableCell className="header-title">ឃុំ</TableCell>
              <TableCell className="header-title-end"></TableCell>
            </TableHead>
            {loading ? (
              <TableBody className="body">
                <TableRow className="body-row">
                  <TableCell colSpan={8} className="body-cell">
                    <LoadingPage />
                  </TableCell>
                </TableRow>
              </TableBody>
            ) : (
              <>
                {tableData?.length === 0 ? (
                  <TableBody className="body">
                    <TableRow className="body-row">
                      <TableCell colSpan={8} className="body-cell">
                        <EmptyData />
                      </TableCell>
                    </TableRow>
                  </TableBody>
                ) : (
                  <TableBody className="body">
                    {tableData?.map((row, index) => {
                      return (
                        <TableRow className="body-row" key={index}>
                          <TableCell className="body-cell-start">
                            {index + 1}
                          </TableCell>
                          {/* <TableCell className="body-cell">
                            {row?.khmer_name}
                          </TableCell>
                          <TableCell className="body-cell">
                            {row?.khmer_name}
                          </TableCell> */}
                          <TableCell className="body-cell">
                            {row?.communeName}
                          </TableCell>

                          <TableCell className="body-cell-end" align="right">
                            <CommuneAction
                              editData={row}
                              setRefetch={refetch}
                            />
                          </TableCell>
                        </TableRow>
                      );
                    })}
                  </TableBody>
                )}
              </>
            )}
          </Table>
        </TableContainer>
      </Box>
    </div>
  );
}
