import { gql } from "@apollo/client";

export const GET_PROVINCE = gql`
  query GetProvinces {
    getProvinces {
      _id
      provinceName
    }
  }
`;
export const CREATE_PROVINCE = gql`
mutation CreateProvince($provinceName: String!) {
  createProvince(provinceName: $provinceName) {
    status
    message
  }
}
`;

export const UPDATE_PROVINCE = gql`
  mutation UpdateProvince($id: ID!, $provinceName: String!) {
    updateProvince(_id: $id, provinceName: $provinceName) {
      status
      message
    }
  }
`;

export const DELETE_PROVINCE = gql`
  mutation DeleteProvince($id: ID!) {
    deleteProvince(_id: $id) {
      status
      message
    }
  }
`;
