import React from "react";
import { Stack, Typography, Avatar } from "@mui/material";
import ContentCopyIcon from '@mui/icons-material/ContentCopy';
import EmptyImage from "../../Assets/empty-box.png"
//Srcs
import "./emptydata.scss";

export default function EmptyData() {

  return (
    <Stack direction="row" justifyContent="center" className="empty-container">
      <Stack direction="column" justifyContent="center" height="300px" spacing={1}>
        <Stack direction="row" justifyContent="center">
          <img src={EmptyImage} alt="icon" className="empty-image"/>
        </Stack>
        <Typography className="text-title">No data</Typography>​
      </Stack>
    </Stack>
  );
}
