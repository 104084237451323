import { gql } from "@apollo/client";

export const CREATE_DISTRICT = gql`
  mutation CreateDistrict($districtName: String!, $province: ID!) {
    createDistrict(districtName: $districtName, province: $province) {
      status
      message
    }
  }
`;

export const UPDATE_DISTRICT = gql`
  mutation UpdateDistrict($id: ID!, $districtName: String!) {
    updateDistrict(_id: $id, districtName: $districtName) {
      status
      message
    }
  }
`;

export const DELETE_DISTRICT = gql`
  mutation DeleteDistrict($id: ID!) {
    deleteDistrict(_id: $id) {
      status
      message
    }
  }
`;

export const GET_DISTRICT = gql`
  query GetDistricts($provinceId: ID!) {
    getDistricts(provinceId: $provinceId) {
      _id
      districtName
      provinceName
      provinceId
    }
  }
`;
