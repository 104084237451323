import React, { useState, useContext } from "react";
import {
  Box,
  Typography,
  Stack,
  TextField,
  Avatar,
  Button,
  InputAdornment,
  IconButton,
} from "@mui/material";
import { useMutation } from "@apollo/client";
import { useFormik, Form, FormikProvider } from "formik";
import * as Yup from "yup";
import { AuthContext } from "../Context/AuthContext";
import { useNavigate } from "react-router-dom";
import EmailOutlinedIcon from "@mui/icons-material/EmailOutlined";
//src
import "./forgotpassword.scss";
// import loginImage from "../Assets/goglobal-school.png";
import ArrowBackIcon from "@mui/icons-material/ArrowBack";
import { FORGOT_PASSWORD } from "../Schema/ForgotPassword";
export default function ForgotPassword() {
  const navigate = useNavigate();
  const [loading, setLoading] = useState(false);
  const { setAlert } = useContext(AuthContext);
  // Alert Message

  const [forgortUserPassword] = useMutation(FORGOT_PASSWORD, {
    onCompleted: ({ forgortUserPassword }) => {
      setLoading(false);
      if (forgortUserPassword?.status) {
        setAlert(true, "success", forgortUserPassword?.description);
        setTimeout(() => {
          navigate("/login");
        }, 3000);
      } else {
        setAlert(true, "error", forgortUserPassword?.description);
      }
    },
    onError: (error) => {
      console.log("Error: ", error);
      setAlert(true, "error", forgortUserPassword?.description);
    },
  });

  const ValidateSchema = Yup.object().shape({
    email: Yup.string().email("Invalid email format").required("Required"),
  });

  const formik = useFormik({
    initialValues: {
      email: "",
    },
    validationSchema: ValidateSchema,
    onSubmit: async (values) => {
      setLoading(true);
      forgortUserPassword({
        variables: {
          email: values.email,
        },
      });
    },
    onError: (error) => {
      console.log("Error: ", error);
    },
  });

  const { errors, touched, handleSubmit, getFieldProps } = formik;

  return (
    <div>
      <FormikProvider value={formik}>
        <Form autoComplete="off" noValidate onSubmit={handleSubmit}>
          <Box className="forgotpassword-page">
            <Box className="background-image" />
            <Stack direction="row" sx={{ ml: 10 }} className="back-btn">
              <Stack direction="column" justifyContent="center">
                <IconButton id="back-button" onClick={() => navigate("/login")}>
                  <ArrowBackIcon sx={{ color: "#0f81c2" }} />
                </IconButton>
              </Stack>
              <Stack
                spacing={2}
                direction="column"
                justifyContent="center"
                className="text-back"
              >
                <label for="back-button">
                  <Typography className="title-back" align="center">
                    Back
                  </Typography>
                </label>
              </Stack>
            </Stack>
            <Stack direction="row" justifyContent="center" alignItems="center">
              <Box className="container">
                <Box className="box-logo">
                  <Avatar
                    alt="logo"
                    // src={loginImage}
                    variant="square"
                    sx={{ width: 120, height: 120 }}
                  />
                </Box>
                <Box className="box-text" sx={{ mt: 1 }}>
                  <Stack
                    spacing={1}
                    direction="column"
                    justifyContent="center"
                    sx={{ width: "300px" }}
                  >
                    <Typography className="title" variant="h6" align="center">
                      Welcome to Student Information
                    </Typography>
                    <Typography
                      align="center"
                      variant="body2"
                      className="sub-title"
                    >
                      Please input your email to recieve a link in order to
                      change password!
                    </Typography>
                  </Stack>
                </Box>

                <Box className="box-login" sx={{ mt: 3 }}>
                  <Stack
                    spacing={4}
                    direction="column"
                    justifyContent="center"
                    sx={{ width: "300px" }}
                  >
                    <TextField
                      fullWidth
                      size="small"
                      className="text-field"
                      placeholder="example@gmail.com"
                      {...getFieldProps("email")}
                      error={Boolean(touched.email && errors.email)}
                      helperText={touched.email && errors.email}
                      InputProps={{
                        startAdornment: (
                          <InputAdornment position="start">
                            <EmailOutlinedIcon className="icon" />
                          </InputAdornment>
                        ),
                      }}
                    />

                    <Button
                      className="btn-sign-in"
                      type="submit"
                      sx={{ ":hover": { backgroundColor: "red" } }}
                    >
                      {loading ? "Sending...." : "Send"}
                    </Button>
                  </Stack>
                </Box>
                <Typography
                  color="grey"
                  align="center"
                  sx={{ mt: 8, letterSpacing: "2px", fontSize: "12px" }}
                >
                  &#169;Copyright 2022, Student Information
                </Typography>
              </Box>
            </Stack>
          </Box>
        </Form>
      </FormikProvider>
    </div>
  );
}
