import React, { useState, useEffect } from "react";
import {
  Typography,
  Stack,
  Box,
  Tab,
  Tabs,
  Button,
  Grid,
} from "@mui/material";
import { Link } from "react-router-dom";
//Srcs
import CreateIcon from "../Assets/create-icon.svg";
import Province from "../Components/AdministrativeDivisions/Province/Province";
import Village from "../Components/AdministrativeDivisions/Village/Village";
import Commune from "../Components/AdministrativeDivisions/Commune/Commune";
import District from "../Components/AdministrativeDivisions/District/District";
//=============================CREATE===============================================
import CreateProvince from "../Components/AdministrativeDivisions/Province/CreateProvince";
import CreateVillage from "../Components/AdministrativeDivisions/Village/CreateVillage";
import CreateCommune from "../Components/AdministrativeDivisions/Commune/CreateCommune";
import CreateDistrict from "../Components/AdministrativeDivisions/District/CreateDistrict";

export default function AdministrativeDivisions() {
  const [open, setOpen] = useState(false);
  const handleOpen = () => setOpen(true);
  const handleClose = () => setOpen(false);
  const [value, setValue] = React.useState("ខេត្ត");

  const handleChange = (event, newValue) => {
    setValue(newValue);
  };

  // ======================= Resize width Screen ======================
  const [width, setWidth] = useState(window.innerWidth);
  const updateDimensions = () => {
    setWidth(window.innerWidth - 50);
  };
  useEffect(() => {
    window.addEventListener("resize", updateDimensions);
    return () => window.removeEventListener("resize", updateDimensions);
  }, []);

  return (
    <div className="page-container">
      <Stack direction="row" spacing={2} className="page-header">
        <Stack direction="column" justifyContent="center">
          <Box className="slash" />
        </Stack>
        <Stack direction="column" justifyContent="center">
          <Link className="page-link-title" to="/setting">
            <Typography className="page-title">ការកំណត់</Typography>
          </Link>
        </Stack>
        <Stack direction="column" justifyContent="center">
          <Typography className="slash-title">/</Typography>
        </Stack>
        <Stack direction="column" justifyContent="center">
          <Typography className="page-title">អាស័យដ្ឋាន</Typography>
        </Stack>
      </Stack>

      <Box sx={{ marginTop: "30px" }}>
        <Grid container spacing={3}>
          <Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
            <Stack
              direction="row"
              alignItems="center"
              justifyContent="space-between"
            >
              <Stack direction="row">
                <Tabs value={value} onChange={handleChange}>
                  <Tab
                    value="ខេត្ត"
                    label="ខេត្ត"
                    sx={{ fontWeight: "bold" }}
                  />
                  <Tab
                    value="ស្រុក"
                    label="ស្រុក"
                    sx={{ fontWeight: "bold" }}
                  />
                  <Tab
                    value="ឃុំ"
                    label="ឃុំ"
                    sx={{ fontWeight: "bold" }}
                  />
                  <Tab
                    value="ភូមិ"
                    label="ភូមិ"
                    sx={{ fontWeight: "bold" }}
                  />
                </Tabs>
              </Stack>
              <Stack direction="row">
                <Button
                  onClick={handleOpen}
                  className="btn-create"
                  startIcon={
                    <img
                      src={CreateIcon}
                      alt="CreateIcon"
                      className="icon-add"
                    />
                  }
                >
                  បន្ថែម
                </Button>
              </Stack>
            </Stack>
            {value === "ខេត្ត" ? (
              <CreateProvince
                open={open}
                handleClose={handleClose}
              />
            ) : null}

            {value === "ស្រុក" ? (
              <CreateDistrict
                open={open}
                handleClose={handleClose}
              />
            ) : null}
            {value === "ឃុំ" ? (
              <CreateCommune
                open={open}
                handleClose={handleClose}
              />
            ) : null}
            {value === "ភូមិ" ? (
              <CreateVillage
                open={open}
                handleClose={handleClose}
              />
            ) : null}
            {/* page Table */}
            {value === "ខេត្ត" ? <Province /> : null}
            {value === "ស្រុក" ? <District /> : null}
            {value === "ឃុំ" ? <Commune /> : null}
            {value === "ភូមិ" ? <Village /> : null}
          </Grid>
        </Grid>
      </Box>
    </div>
  );
}
