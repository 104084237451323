import React, { useEffect, useState } from "react";
import {
  Typography,
  Stack,
  Box,
  Button,
  TableContainer,
  Table,
  TableRow,
  TableCell,
  TableBody,
  TableHead,
  TextField,
  Grid,
  InputAdornment,
  Avatar,
} from "@mui/material";
import { Link } from "react-router-dom";
import { useQuery } from "@apollo/client";
//Icons
import SearchIcon from "@mui/icons-material/Search";
//Srcs
import "../Style/pagestyle.scss";
import LoadingPage from "../Components/Include/LoadingPage";
import { GET_ALL_POSITIONS } from "../Schema/Positions";
import EmptyData from "../Components/Include/EmptyData";
import PositionsAction from "../Components/Positions/PositionsAction";
import PositionsForm from "../Components/Positions/PositionsForm";
import CreateIcon from "../Assets/create-icon.svg";

export default function Positions() {
  const [loading, setLoading] = useState(true);

  const [open, setOpen] = useState(false);
  const handleOpen = () => setOpen(true);
  const handleClose = () => setOpen(false);

  const [tableData, setTableData] = useState([]);
  const [keyword, setKeyword] = useState("");

  const { refetch } = useQuery(GET_ALL_POSITIONS, {
    variables: {
      keyword: keyword,
    },
    onCompleted: ({ getPositions }) => {
      setTableData(getPositions);
      setLoading(false);
    },
    onError: (err) => {
      console.log("Error::", err?.message);
    },
  });

  // ======================= Resize width Screen ======================
  const [width, setWidth] = useState(window.innerWidth);
  const updateDimensions = () => {
    setWidth(window.innerWidth - 50);
  };
  useEffect(() => {
    window.addEventListener("resize", updateDimensions);
    return () => window.removeEventListener("resize", updateDimensions);
  }, []);

  return (
    <div className="page-container">
      <Stack direction="row" spacing={2} className="page-header">
        <Stack direction="column" justifyContent="center">
          <Box className="slash" />
        </Stack>
        <Stack direction="column" justifyContent="center">
          <Link className="page-link-title" to="/setting">
            <Typography className="page-title">ការកំណត់</Typography>
          </Link>
        </Stack>
        <Stack direction="column" justifyContent="center">
          <Typography className="slash-title">/</Typography>
        </Stack>
        <Stack direction="column" justifyContent="center">
          <Typography className="page-title">តួនាទី</Typography>
        </Stack>
      </Stack>

      <Box sx={{ marginTop: "30px" }}>
        <Grid container spacing={3}>
          <Grid item xs={6} sm={4} md={4} lg={3} xl={2.4}>
            <Typography className="header-text">ស្វែងរកឈ្មោះតួនាទី</Typography>
            <TextField
              placeholder="ស្វែងរកឈ្មោះតួនាទី"
              size="small"
              className="search-field"
              onChange={(e) => setKeyword(e.target.value)}
              InputProps={{
                startAdornment: (
                  <InputAdornment
                    position="start"
                    sx={{ mr: 1, color: "black" }}
                  >
                    <SearchIcon />
                  </InputAdornment>
                ),
              }}
            />
          </Grid>

          <Grid item xs={6} sm={8} md={8} lg={9} xl={9.6}>
            <Stack direction="row" justifyContent="right">
              <Button
                onClick={handleOpen}
                className="btn-create"
                startIcon={
                  <img src={CreateIcon} alt="CreateIcon" className="icon-add" />
                }
              >
                បង្កើត
              </Button>
              <PositionsForm
                open={open}
                handleClose={handleClose}
                setRefetch={refetch}
                title={"Create"}
              />
            </Stack>
          </Grid>
        </Grid>
      </Box>

      <Box className="body-container">
        <TableContainer sx={{ maxWidth: `${width}px` }}>
          <Table className="table" aria-label="simple table">
            <TableHead className="header-row ">
              <TableCell className="header-title-start" width="5%">ល.រ</TableCell>
              <TableCell className="header-title" width="85%">ឈ្មោះតួនាទី</TableCell>
              <TableCell className="header-title-end" width="10%"></TableCell>
            </TableHead>
            {loading ? (
              <TableBody className="body">
                <TableRow className="body-row">
                  <TableCell colSpan={6} className="body-cell">
                    <LoadingPage />
                  </TableCell>
                </TableRow>
              </TableBody>
            ) : (
              <>
                {tableData?.length === 0 ? (
                  <TableBody className="body">
                    <TableRow className="body-row">
                      <TableCell colSpan={6} className="body-cell">
                        <EmptyData />
                      </TableCell>
                    </TableRow>
                  </TableBody>
                ) : (
                  <TableBody className="body">
                    {tableData?.map((row, index) => {
                      return (
                        <TableRow className="body-row" key={index}>
                          <TableCell className="body-cell-start">
                            {index + 1}
                          </TableCell>
                          <TableCell className="body-cell">
                            {row?.title}
                          </TableCell>
                          <TableCell className="body-cell-end" align="right">
                            <PositionsAction editData={row} setRefetch={refetch} />
                          </TableCell>
                        </TableRow>
                      )
                    })}
                  </TableBody>
                )}
              </>
            )}
          </Table>
        </TableContainer>
      </Box>
    </div>
  );
}
